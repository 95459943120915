var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  return function (e, t) {
    t.prototype.isSameOrAfter = function (e, t) {
      return this.isSame(e, t) || this.isAfter(e, t);
    };
  };
});
export default exports;